import "jquery.singlePageNav.js";
import "jquery.magnific-popup.js";

function getOffSet() {
    var _offset = 450;
    var windowHeight = window.innerHeight;

    if (windowHeight > 500) {
        _offset = 400;
    }
    if (windowHeight > 680) {
        _offset = 300
    }
    if (windowHeight > 830) {
        _offset = 210;
    }

    return _offset;
}

function setParallaxPosition($doc, multiplier, $object) {
    var offset = getOffSet();
    var from_top = $doc.scrollTop(),
        bg_css = 'center ' + (multiplier * from_top - offset) + 'px';
    $object.css({ "background-position": bg_css });
}

// Parallax function
// Adapted based on https://codepen.io/roborich/pen/wpAsm        
function background_image_parallax($object, multiplier, forceSet) {
    multiplier = typeof multiplier !== 'undefined' ? multiplier : 0.5;
    multiplier = 1 - multiplier;
    var $doc = $(document);
    // $object.css({"background-attatchment" : "fixed"});

    if (forceSet) {
        setParallaxPosition($doc, multiplier, $object);
    } else {
        $(window).scroll(function () {
            setParallaxPosition($doc, multiplier, $object);
        });
    }
};

function background_image_parallax_2($object, multiplier) {
    multiplier = typeof multiplier !== 'undefined' ? multiplier : 0.5;
    multiplier = 1 - multiplier;
    var $doc = $(document);
    // $object.css({"background-attachment" : "relative"});
    $(window).scroll(function () {
        var firstTop = $object.offset().top,
            pos = $(window).scrollTop(),
            yPos = Math.round((multiplier * (firstTop - pos)) - 186);

        var bg_css = 'center ' + yPos + 'px';

        $object.css({ "background-position": bg_css });
    });
};

function load_page() {
    // Hero Section - Background Parallax
    background_image_parallax($(".tm-parallax"), 0.30, false);
    background_image_parallax_2($("#contact"), 0.80);

    // Handle window resize
    window.addEventListener('resize', function () {
        background_image_parallax($(".tm-parallax"), 0.30, true);
    }, true);

    // Close mobile menu after click 
    $('#tmNav button').on('click', function () {
        if (window.matchMedia("(max-width: 767px)").matches) {
            $('.navbar-collapse').toggle('show');
        }
    });
    $('#tmNav a').on('click', function () {
        if (window.matchMedia("(max-width: 767px)").matches) {
            $('.navbar-collapse').toggle('show');
        }
    })

    // Scroll to corresponding section with animation
    $('#tmNav').singlePageNav();
}

export { load_page };